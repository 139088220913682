import React, { useState, useMemo } from "react";
import {
  TextField,
  Container,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  Link,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useLocation } from "@reach/router";
import { Link as GatsbyLink } from "gatsby";

const logIn = async (
  username: string,
  password: string,
  rememberMe: boolean
) => {
  const res = await fetch("/users/sign_in.json", {
    method: "POST",
    body: JSON.stringify({
      user: {
        email: username,
        password,
        remember_me: rememberMe,
      },
    }),
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    redirect: "manual",
  });

  if (res.status === 200) {
    return true;
  }

  return false;
};

export default function SignIn() {
  const [errors, setErrors] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const location = useLocation();

  const messageText = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);

    return searchParams.get("message");
  }, [location]);

  const [message, setMessage] = useState<string | undefined>(
    () => messageText || undefined
  );

  return (
    <Container maxWidth="xs">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          margin: "20px 0px",
        }}
      >
        <Typography variant="h1">Sign In</Typography>

        {message && <Alert severity="info">{message}</Alert>}

        <form
          style={{ width: "100%" }}
          onSubmit={async (e) => {
            e.preventDefault();

            setMessage(undefined);
            setErrors(undefined);
            const didLogIn = await logIn(email, password, rememberMe);

            if (didLogIn) {
              (window.location as any) = "/";
            } else {
              setEmail("");
              setPassword("");
              setErrors("Username/password was incorrect");
            }
          }}
        >
          <TextField
            label="Email"
            margin="normal"
            fullWidth
            variant="outlined"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            autoComplete="email"
            autoFocus
            error={errors !== undefined}
            helperText={errors}
          />

          <TextField
            label="Password"
            margin="normal"
            fullWidth
            variant="outlined"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />

          <FormControlLabel
            control={
              <Checkbox
                value="remember"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                color="primary"
              />
            }
            label="Remember me"
          />

          <Button variant="contained" color="primary" fullWidth type="submit">
            Sign In
          </Button>
        </form>

        <form
          style={{ marginTop: "16px", marginBottom: "8px", width: "100%" }}
          action="/users/auth/google_oauth2"
          method="post"
        >
          <Button fullWidth variant="contained" type="submit">
            Sign in with Google
          </Button>
        </form>

        <form
          style={{ marginTop: "8px", marginBottom: "8px", width: "100%" }}
          action="/users/auth/orcid"
          method="post"
        >
          <Button variant="contained" fullWidth type="submit">
            Sign in with ORCID
          </Button>
        </form>

        <div>
          <Link component={GatsbyLink} to="/sign_up">
            Sign up
          </Link>
        </div>

        <div>
          <Link component={GatsbyLink} to="/forgot_password">
            Forgot password?
          </Link>
        </div>

        <div>
          <Link component={GatsbyLink} to="/resend_confirmation">
            Resend confirmation email?
          </Link>
        </div>
      </div>
    </Container>
  );
}
